import React from "react"
import Fade from "react-reveal/Fade"

class TextTitleImage3 extends React.Component {
  constructor(props) {
    super(props)
    this.state = { marginLeft: "0px", color: "#FFF6DD" }
    this.handleScroll = this.handleScroll.bind(this)
  }
  handleScroll() {
    let element = document.getElementById("headingTextTitleImage3")
    let rect = element.getBoundingClientRect()
    let scrollPercent = (rect.y / window.innerHeight) * 100
    let xOffSet = (scrollPercent - 10) * 4
    if (xOffSet < 0) {
      xOffSet = 0
    } else if (scrollPercent > 120) {
      xOffSet = 120 * 4
    }
    xOffSet = xOffSet + "px"
    this.setState({ marginLeft: xOffSet, color: "#FFF6DD" })
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  render() {
    const {
      title1,
      text1,
      title2,
      text2,
      title3,
      text3,
      image,
      heading,
      flex,
    } = this.props
    return (
      <div className="bg-sand overflow-hidden">
        <div className="lg:w-fourteen md:w-ten mx-auto lg:px-30 px-5 md:px-16 md:pt-20 md:pb-25 py-15 lg:pt-30 lg:pb-40 sm:px-16">
          <h2
            className="heading mb-20 subline font-knockout text-sand -mr-100"
            style={this.state}
            id="headingTextTitleImage3"
          >
            {heading}
          </h2>

          <div className={`${flex} flex-col`}>
            <div className="flex-1">
              <Fade duration={1300}>
                <h3 className="subheading font-sofia-bold mb-6 text-2xl">
                  {title1}
                </h3>
                <p className="text text-read2 text-lg mb-20">{text1}</p>
              </Fade>
              <Fade duration={1300}>
                <h3 className="subheading font-sofia-bold mb-6 text-2xl">
                  {title2}
                </h3>
                <p className="text text-read2 text-lg mb-20">{text2}</p>
              </Fade>
              <Fade duration={1300}>
                <h3 className="subheading font-sofia-bold mb-6 text-2xl">
                  {title3}
                </h3>
                <p className="text text-read2 text-lg mb-12">{text3}</p>
              </Fade>
            </div>
            <div className="flex-1 -mt-4">
              <Fade duration={1300}>
                <img src={image} className="sm:pr-20" alt="Projects" />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TextTitleImage3
