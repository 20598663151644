import React from "react"
import Link from "gatsby-link"
import Fade from 'react-reveal/Fade';

const Back = ({ text }) => {
  return (
    <div className="bg-darkYellow lg:h-45 sm:h-25 md:h-32 h-12">
      <div className="flex justify-center items-center h-full">
        <Link to="/"><p className="m-0 lg:text-30 md:text-25 sm:text-20 text-sm -ml-4 md:-ml-10 lg:-ml-12 font-sofia-med"><span className="arrow">← &nbsp;</span>Back to main page</p></Link>
      </div>
    </div>
  )
}

export default Back
