import React from "react"
import Fade from 'react-reveal/Fade';

const UnderHeroText = ({ text }) => {
  return (
    <div className="bg-black">
      <Fade duration={1300}>
      <div className="lg:max-w-twelve md:w-ten mx-auto justify-between flex flex-col">
        <p
          className="text-base text text-white text-lg lg:ml-32 md:ml-40 sm:ml-15 ml-15 mr-10 mb-12 lg:mb-0 sm:w-500 lg:w-690"
          style={{ color: "#FFFFFF" }}
        >
          {text}
        </p>
      </div>
      </Fade>
    </div>
  )
}

export default UnderHeroText