import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubHero from "../components/SubHero"
import UnderHeroText from "../components/UnderHeroText"
import TextTitleImage from "../components/TextTitleImage"
import TextTitleImage3 from "../components/TextTitleImage3"
import TextTitleImage6 from "../components/TextTitleImage6"
import Back from "../components/Back"
import img1 from "../images/illustrations_sm-planning.svg"
import img2 from "../images/illustrations_sm-design.svg"
import img3 from "../images/illustrations_sm-development.svg"

const services = () => {
  // const siteTitle = data.site.siteMetadata.title

  return (
    // <Layout location={location} title={siteTitle}>
    <Layout>
      <SEO title="Services | Cider Consulting" />
      <SubHero
        title="Services"
        text="From planning &amp; strategy,
      high level development, and testing."
      />
      <div className="lg:h-30 md:h-25 lg:-mt-0 md:-mt-10 sm:h-20 h-10 bg-black" />
      <UnderHeroText text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultrices, eros eget laoreet commodo, erat nisl elementum dui, sed volutpat diam felis vestibulum mi.  erat nisl elementum dui, sed volutpat diam felis vestibulum mi." />
      <div className="lg:h-30 md:h-25 sm:h-20 h-10 bg-black" />
      <TextTitleImage
        heading="Planning"
        title1="Product Strategy"
        text1="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title2="Business Analysis"
        text2="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title3="MVP Definition"
        text3="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        image={img1}
        title4="Market &amp; User Reaserch"
        text4="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        flex="flex"
      />
      <TextTitleImage3
        heading="Design"
        title1="User Experience"
        text1="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title2="Visual designs"
        text2="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title3="Prototyping"
        text3="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        image={img2}
        flex="flex sm:flex-row-reverse"
      />
      <TextTitleImage6
        heading="Development"
        title1="Cross-Platform"
        text1="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title2="Native iOS &amp; Android"
        text2="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title3="React Native"
        text3="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title4="QA Automation"
        text4="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title5="Web"
        text5="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        title6="E-Commerce"
        text6="We believe in exploring ideas that make work better for our clients, and promoting a sense of ownership over our work to deliver results we are all proud of. Learning is growing, and we want you to have every opportunity to stay fresh and up to date on technologies and product development"
        image={img3}
        flex="flex"
      />
      <Back />
    </Layout>
  )
}

export default services
