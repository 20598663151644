import React from "react"
import Fade from "react-reveal/Fade"
import Bubbles from "../components/Bubbles"

const SubHero = ({ title, text }) => {
  return (
    <>
      <Bubbles />
      <div className="overflow-hidden pt-25 bg-black">
        <div className="lg:w-fourteen md:w-ten lg:px-32 lg:pb-40 lg:pt-30 md:px-16 md:pt-20 md:pb-25 sm:px-16 sm:py-20 px-5 py-15 mx-auto flex flex-col justify-center h-screen">
          <h1
            className="lg:text-160 md:text-140  sm:text-100 text-50 headline text-yellow mt-10 md:mb-10 mb-6 relative z-0"
            style={{ fontFamily: "Knockout" }}
          >
            {title}
          </h1>
          <h2
            className="font-bold font-sofia-bold text-white text-40 sm:md-60 md:text-70 lg:text-100 md:w-820 lg:w-1100 sm:w-640 w-320 relative z-20"
            style={{ letterSpacing: "-2px" }}
          >
            {text}
          </h2>
        </div>
      </div>
    </>
  )
}

export default SubHero
